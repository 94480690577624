import { staticImprovementMapSpanish } from "../const/improvements.conts";
import getPriceRangeParam from "./price-range-param.helper";
import { queryFormatter } from "./query-string-helper";

export const parseRealestateFiltersToUrl = (values: FilterRealEstate) => {
  const {
    city,
    locations,
    typeProperty = [],
    sector,
    onlyUnits,
    ...rest
  } = values;

  let formattedUrl = queryFormatter({ ...rest, ...validatePriceRange(rest) });

  let typePropertyFilters = getTypeProperty(typeProperty);
  let locationsFilters = serializeArrayOfObject(locations);
  let SectorFilters = serializeArrayOfObject(sector);

  const ciudad = locations?.at(0)?.description;
  const sectores = sector?.map(sector => sector.description).join('|');

  let complexFilters = queryFormatter({
    typeProperty: typePropertyFilters,
    locations: locationsFilters,
    sector: SectorFilters,
    onlyUnits,
  });

  if (complexFilters) formattedUrl += '&' + complexFilters;

  //Add cosmetic/stats params (should be removed in parseUrlToRealestateFilters)
  if (ciudad) formattedUrl += '&ciudad=' + ciudad;
  if (sectores) formattedUrl += '&sectores=' + sectores;
  if (onlyUnits?.length && onlyUnits.length < 2) formattedUrl += formatOnlyUnitsParams(onlyUnits);
  if (rest.amountMax || rest.amountMin) formattedUrl += getPriceRangeParam(values);
  if (rest.improvements?.length) formattedUrl += formatImprovementsParams(rest.improvements);

  return formattedUrl;
};

let lastValidPriceRange = {};

export const validatePriceRange = ({ amountMax, amountMin }: any) => {
  if (amountMax && amountMin && +amountMax < +amountMin) return lastValidPriceRange;

  lastValidPriceRange = { amountMax, amountMin };
  return lastValidPriceRange
}

function formatOnlyUnitsParams(onlyUnits: string[]) {
  return '&tipo_construccion=' + (onlyUnits[0] === 'true' ? 'construida' : 'en construccion');
}

function formatImprovementsParams(improvements: string[]) {
  return "&amenidades=" + improvements.map(imp => staticImprovementMapSpanish.get(+imp)).join("|")
}

export const parseUrlToRealestateFilters = (values: any) => {
  const { city, locations, sector, typeProperty = [], ...rest } = values;

  let locationFilters = serializeArrayOfString(locations);
  let sectorFilters = serializeArrayOfString(sector);

  //Remove cosmetic/stats params
  ['ciudad',
    'sectores',
    'tipo_construccion',
    'mm',
    'mma',
    'amenidades'].map(prop => delete rest[prop]);

  return {
    locations: locationFilters,
    typeProperty: typeProperty,
    sector: sectorFilters,
    ...rest,
  };
};

// We are serializing Array of object into this kind of notation
// Array[] = ["key-value&"]
// the & is just a delimiter
function serializeArrayOfObject(
  array: Array<ILocations | ITypeProperty | any> = []
) {
  let newArray: Array<string> = [];

  for (let index = 0; index < array.length; index++) {
    const element = array[index];

    let _string = Object.keys(element).reduce((prev, current) => {
      return prev + `${current}-${element[current]}&`;
    }, "");

    newArray.push(_string);
  }

  return newArray;
}

// setting back the array of string we get from the url
// thanks to the previous function
function serializeArrayOfString(array: Array<string> = []) {
  let newArray: Array<any> = [];

  for (let index = 0; index < array.length; index++) {
    const element = array[index];

    const keyValues = element.split("&").filter((e) => e != "");
    const obj: any = {};

    keyValues.forEach((keyValue) => {
      const [key, value] = keyValue.split("-");

      let finalValue: any = value;

      if (value == "true" || value == "false") {
        finalValue = value == "true";
      }

      obj[key] = finalValue;
    });

    newArray.push(obj);
  }

  return newArray;
}

// function transformSpecialFilters(value: string) {
//   if (!value.includes(">") && parseInt(value) >= 5) {
//     return ">4";
//   }
//   return value;
// }

// function concatSpecialFilters(value: string, property: string) {
//   if (value) {
//     return "&" + queryFormatter({ [property]: transformSpecialFilters(value) });
//   }
//   return "";
// }

function getTypeProperty(types: Array<ITypeProperty> = []) {
  return types.map((item: ITypeProperty) => {
    if (item?.slug) {
      return item.slug;
    }

    if (item?.value) {
      return item.value;
    }

    if (typeof item === "string") {
      return item;
    }

    return "";
  });
}

export interface FilterRealEstate {
  amountMax?: string;
  amountMin?: string;
  bathrooms?: string;
  bedrooms?: string;
  businessTypes: "lease" | "sale";
  city?: Array<any>;
  currencyType: "us" | "rd";
  floorLevels?: string;
  improvements?: Array<string>;
  locations?: Array<ILocations>;
  parkings?: string;
  propertyStatus?: Array<string>;
  realEstateType?: any;
  search?: string;
  typeProperty?: Array<ITypeProperty>;
  sector?: Array<ILocations>;
  orderBy?: string;
  onlyUnits: string[];
  recent?: number;
}

interface ITypeProperty {
  id: number;
  description: string;
  slug: string;
  value?: string;
}

interface ILocations {
  citie: string;
  description: string;
  id: number;
  isSantoDomingo: boolean;
  isSector: boolean;
  city_id?: number;
}
